<template>
  <base-section
    id="product-technology"
    class="accent"
  >
    <base-section-headingTwo
      title="Industries We Supply Specialty Chemicals"
      icon="mdi-factory"
      outlined
    />

    <v-container>
      <v-row>
        <v-col
          v-for="{title, src, text, n} in contents"
          :key="n"
          cols="12"
          md="3"
          sm="12"
        >
          <v-hover>
            <template #default="{ hover }">
              <base-img
                :height="$vuetify.breakpoint.mdAndUp ? 400 : 200"
                :src="src"
                color="grey lighten-1"
                flat
                tile
              >
                <v-overlay
                  :value="hover"
                  absolute
                  color="secondary"
                  opacity=".7"
                >
                  <base-subheading
                    :title="title"
                    class="text-uppercase mx-5 px-5"
                    align="center"
                    mobile-size="h6"
                    style="line-height:100%;"
                  />
                  <base-body
                    class="text-center mx-5 px-5"
                    :text="text"
                    style="line-height:120%;"
                  />
                </v-overlay>
              </base-img>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProductTechnology',
    data: () => {
      return {
        contents: [
          {
            title: 'Rubber chemical industry',
            text: '',
            src: require('@/assets/technology-1.jpg'),
          },
          {
            title: 'chemical industry',
            text: '',
            src: require('@/assets/technology-2.jpg'),
          },
          {
            title: 'Oleo-chemical industry',
            text: '',
            src: require('@/assets/technology-3.jpg'),
          },
          {
            title: 'Plastic polymer industry',
            text: '',
            src: require('@/assets/technology-4.jpg'),
          },
        ],
      }
    },
  }
</script>
